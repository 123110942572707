<template>
  <!-- 视频展示 -->
  <div>
    <div class="main-contain">
      <video
        src
        width="300"
        height="200"
        controls
        controlslist="nodownload"
        @contextmenu.prevent="handleRMouse"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data () {
    return {
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/more.png"),
    }
  },
  methods: {
    handleRMouse (e) {
      e.preventDefault()
    },
    initdata (obj) {
      //console.log(obj)
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 20px;
  //   border: 1px solid red;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 400px;
  overflow-y: auto;
  .elimage {
    margin-top: 10px;
    margin-right: 50px;
    width: 233px;
    height: 158px;
    border-radius: 4px;
  }
}
</style>
