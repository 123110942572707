<template>
  <!-- 虚拟仿真实验 -->
  <div class="item_contain">
    <template v-if="datalist && datalist.length > 0">
      <div class="item" v-for="(item, index) in datalist" :key="index">
        <div class="item_top">
          <div class="left-image">
            <el-image class="elimage" :src="item.cover" />
          </div>
          <div class="right-content">
            <div class="content-title">{{ item.name }}</div>
            <div class="intro-title">
              <div class="title-item">
                <div class="item-text">所属学科:</div>
                <div class="item-text">{{ item.subject }}</div>
              </div>
              <div class="title-item">
                <div class="item-text">负责人:</div>
                <div class="item-text">{{ item.leader_name }}</div>
              </div>
            </div>
            <div class="people-num">
              <el-image class="elimage" :src="icon1" />
              <div class="num">已参与人次:</div>
              <div class="num">{{ item.join_count }}</div>
            </div>
            <div class="relatedchapters">
              <div class="left">
                <el-image class="elimage" :src="icon2" />
                <div class="num">关联章节</div>
                <div class="moretext" @click="tomore(item)">查看</div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </template>
    <template v-else>
      <div class="is-empty" style="text-align: center;">
        <img :src="no_math" style="width: 50%"/>
        <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;margin-bottom: 30px;">
          <span>暂无实验</span>
        </div>
      </div>
    </template>
    <Customdialog
      ref="customdialog"
      :showclose="true"
      title="关联章节"
      type="max"
      class="customdialog"
      style="width:30%"
    >
      <div slot="dialogbody" class="dialogbody">
        <Outlinetable ref="outlinetable"></Outlinetable>
      </div>
    </Customdialog>
  </div>
</template>
<script>
import { getVRList } from "@/api/vr"
import Customdialog from "@/components/customdialog.vue"
import Outlinetable from "./outlinetable"

export default {
  name: "Courseintroduction",
  components: { Customdialog, Outlinetable },
  data () {
    return {
      course_sn: "",
      datalist: [],
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/矢量智能对象.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      no_math: require('@/assets/no_math1.png'),
    }
  },
  methods: {
    initdata (obj) {
      this.course_sn = obj.course_sn
      this.getVRList({
        course_sn: obj.course_sn,
      })
    },
    tomore (item) {
      this.$refs.customdialog.dialogopenbt()
      this.$nextTick(() => {
        this.$refs.outlinetable.initdata(this.course_sn, item)
      })
    },
    canclebt () {
      this.$refs.customdialog.dialogclosebt()
    },
    submitbt () {
      this.$refs.customdialog.dialogclosebt()
    },

    getVRList (params) {
      getVRList(params)
        .then((response) => {
          this.datalist = response.data.data
        })
        .catch((error) => {
          //console.log(error);
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.item_contain {
  //  border: 1px solid red;
  padding: 10px 0px;
  min-height: 400px;
  width: 100%;
  overflow-y: auto;

  .item {
    // border: 1px solid red;
    //   padding: 10px;
    .item_top {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // height: 200px;
      // padding-top: 20px;
      .left-image {
        margin-left: 20px;
        // width: 320px;
        height: 140px;
        border-radius: 4px;

        .elimage {
          width: 240px;
          height: 140px;
          border-radius: 5px;
        }
      }

      .right-content {
        // border: 1px solid red;
        height: 140px;
        margin-left: 20px;
        padding-top: 20px;

        .content-title {
          font-size: 16px;

          font-weight: 500;
          color: #000000;
        }

        .intro-title {
          margin-top: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .title-item {
            margin-right: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item-text {
              margin-right: 10px;
              font-size: 16px;

              font-weight: 400;
              color: #666666;
            }
          }
        }

        .people-num {
          margin-top: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .num {
            margin-left: 6px;
            font-size: 16px;

            font-weight: 400;
            color: #666666;
          }
        }

        .relatedchapters {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .elimage {
              width: 18px;
              height: 15px;
            }

            .num {
              margin-left: 6px;
              font-size: 16px;

              font-weight: 400;
              color: #666666;
            }
          }

          .moretext {
            margin-left: 20px;
            font-size: 16px;

            font-weight: 400;
            color: #3d84ff;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }

    .line {
      margin-left: 20px;
      border-bottom: 1px solid #ebeef5;
    }
  }

  .customdialog {
    .dialogbody {
      padding: 10px;
      // padding-left: 140px;
      // border: 1px solid red;

      .item {
        margin-top: 20px;

        .item-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-title-text {
            margin-right: 30px;
            font-size: 20px;

            font-weight: bold;
            color: #666666;
          }
        }

        .item-item {
          margin-top: 20px;
          margin-left: 70px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-item-icon {
            width: 12px;
            height: 12px;
            background: #c6c6d3;
            border-radius: 50%;
          }

          .item-item-num {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 18px;

            font-weight: 400;
            color: #666666;
          }

          .item-item-text {
            font-size: 18px;

            font-weight: 400;
            color: #666666;
          }
        }
      }
    }

    .dialogfooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 100px;

      .bt {
        margin-right: 10px;
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #7f7f7f;
        border-radius: 4px;
        user-select: none;
        cursor: pointer;
        font-size: 15px;

        font-weight: 400;
        color: #222222;
      }

      .btactive {
        background: #3d84ff;
        border: 1px solid #3d84ff;
        color: white;
      }
    }
  }
}
</style>
